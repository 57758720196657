<template>
  <div class="component-fullwidth">
    <div class="flex-container flex-full no-padding no-margin">
      <div class="elem-flex">
        <div class="title-page">
          {{ $t("page_title_applicationmanagement") }}
          <div class="other">
            <div class="cont-new-job-button">
              <!-- <div
                class="btn-green radius"
                @click="newOfferForm"
              >
                {{ $t("btn_addnewjoboffer") }}
              </div> -->
              &nbsp;
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="elem-flex contentbox box-shadow offer-cont">
      <div id="list-offer">
        <OfferList :selectable="true" @initialized="() => {this.offerInit = true}"></OfferList>
      </div>
    </div>
    <div class="inner-container">
      <div class="applicant-actions flex-container">
        <div class="applications" v-if="offerInit">
          <applicationManagement></applicationManagement>
        </div>
        <loading v-else/>

      </div>
    </div>
  </div>
</template>

<script>

import { mapState } from 'vuex';
import OfferList from '@/views/Employee/EmployeeDashboard/OfferList.vue';
import applicationManagement from '@/components/EmployeeComponents/JobManagement/applicationManagement.vue';
import loading from '../../../components/utilityPartials/_loading.vue';

export default {
  name: 'ApplicationManagement',
  components: {
    OfferList,
    applicationManagement,
    loading,
  },
  data() {
    return {
      offerInit: false,
    };
  },
  computed: {
    ...mapState('company', {
      companyInfo: (state) => state.companyInfo,
      loading: (state) => state.loadingInfo,
    }),

  },
  mounted() {
    this.$store.dispatch('company/getInfo');
  },
  methods: {
    newOfferForm() {
      this.$store.dispatch('company/setNewOfferApplication');
      this.$router.push('/job-management');
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/_variables.scss";
  #list-offer{
    width: 100%;
    position: relative;
    height: 100%;
    overflow: visible;

  }
  .subtitle-page{
    margin-bottom: 1vh;
  }
  .cont-new-job-button{
    display: flex;
    justify-content: flex-end;
  }
  .offer-cont{
    background-color: $text-color;
    margin-bottom: 3vh;
  }

  .applicant-actions{
    height: 100%;
    overflow: hidden;
    justify-content: space-between;
    position: relative;

    .applications{
      flex: 10;
      position: relative;
      height: 100%;
    }
    .processes{
      flex: 4;

    }
  }
  .title-page{
    &.shortened{
      font-size: 2vh;
      margin-bottom: .5vh;
    }
  }
</style>
