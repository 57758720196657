<template>
<div class="application-management">
  <div class="top-bar">
    <div class="title">
      Applications
    </div>
    <div class="right-part" :class="columns.length <= 5 ? '' : 'decal'">
      <div class="criteria-entries">
        <div class="entry" v-for="bubble in criteriaList" :key="bubble._id">
        <div class="bubble radius"
          :style="`background-color:${bubble.color || '#000'}`" />
          {{bubble.name}}
          {{bubble.category === 'minExp'? (bubble.name > 1 ?'Years':'Year'):''}}
        </div>
      </div>
      <div class="add-column">
        <div class="btn-green small radius" @click="() => openEditModal(false)" >
          Add New Column
        </div>
      </div>
    </div>
  </div>
  <div class="cont-array" v-if="columns.length <= 5" >
    <div class="inner-array">
      <div v-for="col in columns"
        :key="col.name"
        class="col-array radius box-shadow" :id="col.status + '-app'">
        <div :class="['head-col', col.status ]">{{col.label}}
          <span class="edit-col"
          @click="openEditModal(col._id)"
          v-if="col._id">Edit</span>
        </div>
        <draggable
          class="cont-col"
          v-model="col.applicants"
          :option="{draggable:'.elem'}"
          v-bind="dragOptions"
          @change="(moveObj) => postChange(moveObj, col.status)"
        >
          <transition-group>
            <user-card
              v-for="element in col.applicants"
              :key="element._id"
              class="elem radius boxshadow-middle"
              :click="() => openProfile(element, col.status)"
              :application="element"
              :criteriaList="criteriaList"
            />
          </transition-group>
        </draggable>
      </div>

    </div>
  </div>
  <div class="cont-array" v-else>
    <splide class=""
      :options="optionsUpcomingSolo"
    >
      <splide-slide
        v-for="col in columns"
        :key="col.name"
        class="col-array radius box-shadow radius"
        :id="col.status + '-app'"
      >
        <div :class="['head-col', col.status ]"
          :style="col.color ? `background-color: ${col.color};` : ''">{{col.label}}
          <span class="edit-col"
          @click="openEditModal(col._id)"
          v-if="col._id">Edit</span>
        </div>
        <draggable
          class="cont-col"
          v-model="col.applicants"
          :option="{draggable:'.elem'}"
          v-bind="dragOptions"
          @change="(moveObj) => postChange(moveObj, col.status)"
        >
          <transition-group>
            <user-card
              v-for="element in col.applicants"
              :key="element._id"
              class="elem radius boxshadow-middle"
              :click="() => openProfile(element, col.status)"
              :application="element"
              :criteriaList="criteriaList"
            />
          </transition-group>
        </draggable>
      </splide-slide>
    </splide>
  </div>
</div>
</template>

<script>
import draggable from 'vuedraggable';
import { Splide, SplideSlide } from '@splidejs/vue-splide';
import UserCard from '@/components/EmployeeComponents/JobManagement/managementPartials/userCard.vue';
import ApplicantProcess from '@/components/EmployeeComponents/JobManagement/processPartials/applicantProcess.vue';
import scrollInit from '@/modules/mixins/perfectScrollInit';
import columnModal from '@/components/EmployeeComponents/JobManagement/managementPartials/columnDef/newColumnModal';

const columnDefs = () => [
  {
    label: 'Last Applications',
    applicants: [],
    status: 'initial',
  },
  {
    label: 'Rejected Applications',
    applicants: [],
    status: 'rejected',
  },
  {
    label: 'Approved Applications',
    applicants: [],
    status: 'approved',
  },
  {
    label: 'Ongoing Process',
    applicants: [],
    status: 'process',
  },
  {
    label: 'Hired Talents',
    applicants: [],
    status: 'hired',
  },
];
export default {
  name: 'applicationManagement',
  components: {
    draggable,
    UserCard,
    Splide,
    SplideSlide,
  },
  mixins: [columnModal],
  data() {
    return {
      widthcontainer: '1500px',
      editable: true,
      isDragging: false,
      delayedDragging: false,

      columns: columnDefs(),

      optionsUpcomingSolo: {
        perPage: 5,
        gap: '1vh',
        padding: '.5vh 0',
        rewind: false,
        pagination: false,
        arrows: true,
        drag: false,
      },

    };
  },
  computed: {
    dragOptions() {
      return {
        animation: 0,
        group: 'applications',
        disabled: !this.editable,
        ghostClass: 'ghost',
      };
    },

    selected() {
      return this.$store.getters['company/selectedOffer'];
    },
    applicantState() {
      if (this.selected) {
        return this.$store.getters['company/currentOfferApplication'].applicants;
      }
      return false;
    },
    criteriaList() {
      return this.$store.getters['company/currentOfferApplication'].criteriaList || [];
    },
    applicationOffer() {
      return this.$store.getters['company/currentOfferApplication']._id;
    },
    companyColumns() {
      return this.$store.state.company.columns;
    },

  },
  mounted() {
    // this.widthcontainer = this.calcWidth();
    if (this.$store.getters['company/selectedOffer']) {
      this.$store.dispatch('company/getUpdatedApplicants', this.$store.state.company.currentOfferApplicationId).then(() => {
        this.sortApplicants(this.$store.getters['company/currentOfferApplication'].applicants);
      });
    }
  },
  methods: {

    calcWidth() {
      return `${(document.querySelector('.col-array').offsetWidth + 5) * document.querySelectorAll('.col-array').length}px`;
    },

    sortApplicants(applicants) {
      this.columns = [...columnDefs(), ...this.$store.getters['company/columns'].map((col) => {
        // eslint-disable-next-line no-param-reassign
        col.applicants = [];
        return col;
      })];

      const indices = {};
      this.columns.forEach((col, index) => {
        indices[col.status] = index;
      });
      applicants.forEach((app) => {
        try {
          if (this.columns[indices[app.status]].applicants) {
            this.columns[indices[app.status]].applicants.push(app);
          } else {
            this.columns[indices[app.status]].applicants = [app];
          }
        } catch (e) {
          this.columns[0].applicants.push(app);
        }
      });

      this.columns.forEach((col) => {
        if (col.applicants.length > 4) {
          scrollInit(`${col.status}-app`);
        }
      });
    },

    postChange(moveObj, status) {
      if (moveObj.added) {
        const user = moveObj.added.element;
        this.updateApplicant(user._id, { status, position: moveObj.added.newIndex }, true);
      } else if (moveObj.moved) {
        const user = moveObj.moved.element;
        this.updateApplicant(user._id, { position: moveObj.moved.newIndex });
      }
    },
    updateApplicant(applicantId, update, statusUpdate = false) {
      const updateBody = {
        offerId: this.$store.state.company.currentOfferApplicationId,
        applicantId,
        applicationUpdate: update,
      };
      if (statusUpdate) {
        updateBody.action = { type: 'statusUpdate' };
      }
      this.$store.dispatch('company/updateApplication', updateBody);
    },
    openProfile(app, status) {
      if (status === 'process') {
        this.openProcess(app);
      } else {
        this.goProfile(app);
      }
    },
    goProfile(applicant) {
      this.$modal.hideAll();
      this.$modal.show(ApplicantProcess,
        {
          applicant,
          offerIdProp: this.$store.state.company.currentOfferApplicationId,
          stepHide: true,
          processComp: ApplicantProcess,
          modalName: 'applicant-info',

        }, {
          name: 'applicant-info',
          width: '25%',
          height: '70vh',
          scrollable: true,
          adaptive: true,
          classes: ['overflow'],
        });
    },
    openProcess(applicant) {
      const offerId = this.$store.state.company.currentOfferApplicationId;
      this.$store.dispatch('company/getUpdatedApplicationTimes',
        { offerId, applicationId: applicant._id }).then(() => {
        this.$modal.show(ApplicantProcess,
          {
            applicant: this.$store.getters['company/applicant'](offerId, applicant._id),
            processComp: ApplicantProcess,
          },
          {
            name: 'applicant-process', width: '70%', height: '70vh', scrollable: true, adaptive: true, classes: ['overflow'],
          });
      });
    },

  },
  watch: {
    isDragging(newValue) {
      if (newValue) {
        this.delayedDragging = true;
        return;
      }
      this.$nextTick(() => {
        this.delayedDragging = false;
      });
    },
    applicantState(newValue) {
      this.sortApplicants(newValue);
    },
    applicationOffer() {
      this.$store.dispatch('company/getUpdatedApplicants', this.$store.state.company.currentOfferApplicationId).then(() => {
        this.sortApplicants(this.$store.getters['company/currentOfferApplication'].applicants);
      });
    },
    companyColumns: {
      handler() {
        this.sortApplicants(this.$store.getters['company/currentOfferApplication'].applicants);
      },
    },
  },

};
</script>

<style lang="scss" scoped>
  @import "@/assets/scss/_variables.scss";
  .application-management{
    width: calc(100% - 2vh);
    // height: calc(100% - 4vh);
    display: flex;
    flex-direction: column;
    background-color: #f0f0f0;
    padding: 2vh 1vh;
    border-radius: 1vh;
    .top-bar{
      width: 100%;
      height: 2vh;
      line-height: 2vh;
      padding-bottom: 2vh;
      position: relative;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .title{
        font-size: 2vh;
        color: #8c28e1;
        font-weight: bold;
      }

      .right-part{
        display: flex;
        align-items: center;
        &.decal{
          margin-right: 8vh;
        }
        .criteria-entries{
          // max-width: 60%;
          margin-right: 2vh;
          display: flex;
          flex-direction: row;
          .entry{
            display: flex;
            margin-right: 1.5vh;
            font-size: 1.3vh;
            &:last-child{
              margin-right: 0;
            }
          }
          .bubble{
            height: 2vh;
            width: 2vh;
            margin-right: .5vh;
          }
        }
        .add-column{
        }
      }

    }
  }
  .cont-array{
    width: 74vw;
    // height: calc(100% - 8vh);    // overflow-x: auto;
    height: 100%;    // overflow-x: auto;
    .inner-array{
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      justify-content: flex-start;
    }
    .col-array{
      width: calc(calc(100% - 2vh) / 3);
      // height: 100%;
      height: 46vh;
      position: relative;
      overflow: hidden;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
      margin-right: 1vh;
      background-color: #ffffff;
      &:last-child{
        margin-right: 0;
      }
      .head-col{
        background-color: #8c28e1;
          color: #fff;
        width: calc(100% - 2vh);
        padding: 1.5vh 1vh;
        display: flex;
        justify-content: space-between;
        font-family: 'MontserratBold';

        &.rejected{
          background-color: #160033;
          color: #fff;

        }
        &.approved{
          background-color: #48ffaf;
          color: #000
        }
        .edit-col{
          font-family: 'MontserratRegular';
          cursor: pointer;
        }
      }
      .cont-col{
        position: relative;
        display: block;
        width: calc(100% - 1vh);
        padding: 0.5vh;
        height: 100%;
        margin: 0 auto;
        span{
          display: block;
          height: 100%;
        }
        ::v-deep .elem{
          position: relative;
          margin-bottom: 1vh;
          max-width: calc(100% - 1vh);
          padding: 0.5vh;
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: flex-start;
          flex-wrap: wrap;
          color: $logo_color_dark;
          background-color: #ffffff;
          transition: all 0.2s;
          cursor: pointer;
          .texts{
            width: calc(100% - 60px);
          }

          .user-card:hover{
            background-color: $logo_color;
            border: 1px solid $logo_color;
            color: #ffffff;
            .bubble-cont{
              &.no-match{
                color: $logo_color_dark;
              }
            }
          }
          &.ghost{
            opacity:0.5;
          }
        }
      }
    }
    .splide{
      .splide__track{
        padding: 0.5vh 0;
      }
    }
  }
</style>

<style lang="scss">
@import "@/assets/scss/_variables.scss";
.cont-array{
  .splide{
    .col-array{
      height: 45vh;
    }
    .splide__track{
      padding: 0.5vh 0;
    }
  }
  .splide__arrows{
    position: absolute;
    right: 0;
    top: 0;
    display: flex;
      transform: translateY(-125%);
    .splide__arrow{
      position: relative;
      top: unset;
      left: unset;
      right: unset;

      z-index: 1;
      width: 2.4vh;
      height: 2.4vh;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      border: none;
      padding: 0;
      opacity: 1;
      background: $green-color;
      &:hover{
        opacity: .7;
      }
      &.splide__arrow--prev{
        margin-right: 1.5vh;
      }
    }
    // transform: translateX(-50%);
  }
}
</style>
