import Column from './columnModal.vue';

export default {
  methods: {
    openEditModal(columnId) {
      this.$modal.show(Column,
        {
          columnId,
        },
        {
          name: 'columnform',
          width: '50%',
          height: '20vh',
          scrollable: true,
          adaptive: true,
          classes: ['overflow'],
        });
    },
  },
};
