<template>
  <div class="role-form">
    <div class="green-circle-button close-button" @click="closeModal">
      <span>X</span>
    </div>
    <div class="radius marginB">
      <div class="subtitle-page no-padding">
        {{this.columnId ? 'Edit Column': 'Add new Column'}}
      </div>
    </div>
    <div class="name-entry entry-role-form radius marginB">
      <label class="label">Column's title</label>
      <input class="v-select" v-model="label" placeholder="Title" requiredVal="label"
      />
    <div class="cont-color-selector">
        <div
          class="selectedcolor radius box-shadow"
          v-if="color !== ''"
          :style="`background-color:${color}`"
        ></div>
        <v-select
          :searchable="true"
          v-model="color"
          label="name"
          :options="colorOption"
          placeholder="Color"
          :reduce="color => color.entry"
          class="no-remove color"
        />
      </div>
    </div>

    <div class="submit">
      <div class="btn-green radius" @click="submit" >Submit</div>
      <div class="btn-green radius reverse" @click="closeModal">Cancel</div>
      <div class="btn-green radius delete" @click="deleteCol">Delete</div>
    </div>

  </div>
</template>

<script>
import Validate from '@/modules/mixins/validate';

export default {
  name: 'ColumnModal',
  props: ['columnId'],
  mixins: [Validate],
  data() {
    return {
      label: '',
      color: '',
      sending: false,
      statusChanged: false,
      colorOption: [
        { name: 'Blue', entry: '#48CEFF' },
        { name: 'Dark Purple', entry: '#160033' },
        { name: 'Green', entry: '#48FFAF' },
        { name: 'Purple', entry: '#8C28E1' },
        { name: 'Red', entry: '#FF4848' },
        { name: 'Yellow', entry: '#FFCE48' },
      ],
    };
  },

  methods: {
    submit() {
      if (this.sending) {
        return;
      }
      this.sending = true;

      const data = { label: this.label, color: this.color };
      if (this.columnId) {
        data._id = this.columnId;
        this.$store.dispatch('company/putColumn', data).then(() => {
          this.closeModal();
        });
      } else {
        this.$store.dispatch('company/postColumn', data).then(() => {
          this.closeModal();
        });
      }
    },

    deleteCol() {
      if (this.sending || !this.validateFields()) {
        return;
      }
      if (this.columnId) {
        this.sending = true;
        this.$store.dispatch('company/deleteColumn', this.columnId).then(() => {
          this.closeModal();
        });
      }
    },

    closeModal() {
      this.$modal.hide(this.modalName ? this.modalName : 'columnform');
    },

  },

  mounted() {
    if (this.columnId) {
      const colData = this.$store.getters['company/column'](this.columnId);
      this.label = colData.label;
      this.color = colData.color;
    }
  },

};
</script>

<style lang="scss">
@import "@/assets/scss/_variables.scss";
.role-form{
  position: relative;
  padding: 1vh;
  .entry-role-form{
    margin-top: 2vh;
    background-color: $new_bg_grey;
    padding: 1vh;
    .v-select{
      margin-top: 1vh;
    }
  }
  .submit{
    width: calc(100% - 1vh);
    margin-top: 2vh;
    margin-right: 1vh;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    .btn-green{
      margin-right: 1vh;
      &:last-child{
        margin-right: 0;
      }
    }
  }
  .cont-color-selector{
    width: 25%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: wrap;
    .color{
      width: calc(100% - 4vh);
    }
    .selectedcolor{
      margin-top: 1vh;
      width: 3.5vh;
      height: 3.5vh;
      margin-right: 0.5vh;
    }
  }
}

</style>
