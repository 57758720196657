<template>
  <div
    class="elem"
    @click="click"
  >
    <div class="user-card radius box-shadow">
      <user-name-tag :user="application.user" :applied="application.updatedAt" />
    </div>
      <div
        v-if="bubbleCriteria(application, criteriaList).length > 0"
        class="bubble-cont"
      >
        <div
          class="critera radius"
          v-for="criteria in bubbleCriteria(application, criteriaList)"
          :style="`background-color:${criteria.color}`"
          :key="`${criteria.name}${application._id}`"
          v-tooltip.top-center="{
            content: contentToolTip(criteria),
            delay: {
              show: 0,
              hide: 0,
            }
          }"
        >
         <!-- {{criteria.category === 'minExp'?`>${criteria.name} Years` : criteria.name}} -->
      </div>
    </div>

  </div>
</template>

<script>
import userNameTag from './userNameTag.vue';

export default
{
  name: 'UserCard',
  props: ['application', 'criteriaList', 'click'],
  components: { userNameTag },
  methods: {
    contentToolTip(critera) {
      if (critera.category === 'minExp') {
        return `>${critera.name} Years`;
      }
      return critera.name;
    },

    // eslint-disable-next-line no-unused-vars
    bubbleCriteria(application, criteria) {
      return criteria.filter((entry) => {
        if (entry.category === 'minExp') {
          if (application.user.professionalStart) {
            const start = this.$moment(application.user.professionalStart);
            const now = this.$moment();
            return now.diff(start, 'years', true) >= Number(entry.name);
          }
          return false;
        }
        if (
          application.user[entry.category]
          && application.user[entry.category].findIndex(
            (userEntry) => userEntry.toLowerCase() === entry.name.toLowerCase(),
          ) > -1
        ) {
          return true;
        }
        return false;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
  @import "@/assets/scss/_variables.scss";
  .elem{
    overflow: hidden;
  }
  .user-card{
    border: 1px solid $fond_light_gray;
    box-sizing: border-box;
    width: 100%;
    padding: 1vh;
    // border-radius: 3%;
  }
  .bubble-cont{
      width: 100%;
      margin-top: -1vh;
      margin-right: 1vh;
      display:flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-end;
      .critera{
        font-size: 12;
        height: 1vh;
        width: 1vh;
        border-radius: 50%;
        padding: 0.5vh;
        color: #ffffff;
        margin-right: 0.5vh;
        &:last-child{
          margin-right: 0;
        }
      }
    }
</style>
